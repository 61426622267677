<template lang="pug">
  .head(v-if="!$store.state.pcshow")
    div.con
      .video
        video(src="../assets/video/video.mp4" ref="video" muted controls poster="../assets/img/index/yilianGl20.png")
          p 你的浏览器不支持video标签
      .hprofile
        h3.conh3 COMPANY PROFILE
        h4.conh4 集团简介
        img(src="../assets/img/index/img (19).png")
        p 上海亿联体创业孵化器管理有限公司（简称亿联体集团）创立于2018年，是一家致力于通过健康产业投资布局，高新科技企业孵化帮扶，文教创新，整合全国医疗健康垂直领域优质资源等方式，打造多元化全产业链的投资商业集团。
        router-link(to="/companyIntro")
            .btn 了解更多
      .hbusiness
        h3.conh3 CORE BUSINESS
        h4.conh4 核心业务
        img(src="../assets/img/index/h5img1.png")
        img(src="../assets/img/index/h5img2.png")
        img(src="../assets/img/index/h5img3.png")
      .hhatch
        h3.conh3 INCUBATION ENTERPRISE
        h4.conh4 孵化企业
        .ul.clearfix
          //- router-link(to="/zhongkeYC")
          //-   .li
          //-     img(src="../assets/img/index/h5img4.png")
          //-     h4 中科医创
          //-     p 公司以CRO、 PBM 、数字 健康社群营销、三大主营业 务构筑商业蓝图。
          //-     .btn 了解更多
          router-link(to="/zhongxinGH")
            .li
              img(src="../assets/img/index/h5img5.png")
              h4 中鑫广汇
              p 致力于为研发、生产、流通、临床、等机构搭建信息交换和项目对接的医疗物流大数据平台。
              .btn 了解更多
          router-link(to="/yilianGl")
            .li
              img(src="../assets/img/index/h5img6.png")
              h4 亿联管理
              p 以70个以上的服务场景覆盖等优势，为药企和代理商提供合规管理服务，以确保合作伙伴业务合规。
              .btn 了解更多
          router-link(to="/chanyeYQ")
            .li
              img(src="../assets/img/index/h5img7.png")
              h4 产业园区
              p 立足大健康相关领域全产业链资源对园区内在孵企业进行创业帮扶，吸引了大量企业入驻园区。
              .btn 了解更多
      .hinfo
        h3.conh3 CORPORATE NEWS
        h4.conh4 企业资讯
        router-link(to="/companyInfo") 
          h4.conh5 查看更多
        .infoagroup
          router-link(to="/companyInfo")
            .li.clearfix
              img(src="../assets/img/index/h5img8.png")
              .new
                .newtitle 科技部将开展国家级科技企业孵化 器认定工作
                .newtime 2021.11.08
          router-link(to="/companyInfo")
            .li.clearfix
              img(src="../assets/img/index/h5img9.png")
              .new
                .newtitle 2021中国大健康产业峰会在深圳成功举办
                .newtime 2021.11.05
          router-link(to="/companyInfo")
            .li.clearfix
              img(src="../assets/img/index/h5img10.png")
              .new
                .newtitle 亿联体：数字化让“大健康”产业更健康
                .newtime 2020.12.11 
      .hpartner
        h3.conh3 COOPERATIVE PARTNER
        h4.conh4 合作伙伴
        .scro
          .imggroup.clearfix
            .li
              img(src="../assets/img/index/h5img30.png")
            .li
              img(src="../assets/img/index/h5img31.png")
            .li
              img(src="../assets/img/index/h5img32.png")
            .li
              img(src="../assets/img/index/h5img33.png")
            .li
              img(src="../assets/img/index/h5img34.png")
            .li
              img(src="../assets/img/index/h5img35.png")
            .li
              img(src="../assets/img/index/h5img36.png")
            .li
              img(src="../assets/img/index/h5img37.png")
            .li
              img(src="../assets/img/index/h5img38.png")
            .li
              img(src="../assets/img/index/h5img39.png")
            .li
              img(src="../assets/img/index/h5img40.png")
            .li
              img(src="../assets/img/index/h5img41.png")
            .li
              img(src="../assets/img/index/h5img42.png")
            .li
              img(src="../assets/img/index/h5img43.png")
            .li
              img(src="../assets/img/index/h5img44.png")
            .li
              img(src="../assets/img/index/h5img45.png")
            .li
              img(src="../assets/img/index/h5img46.png")
            .li
              img(src="../assets/img/index/h5img47.png")
            .li
              img(src="../assets/img/index/h5img48.png")
            .li
              img(src="../assets/img/index/h5img49.png")

      .hcooperation
        h3.conh3 COOPERATIVE APPLICATION
        h4.conh4 合作申请
      .from
        .li.clearfix
          lable 姓名
            span *
          input(type="text", v-model="name", placeholder="请输入您的姓名")
        .li.clearfix
          lable 电话
            span *
          input(type="text", v-model="phone", placeholder="请输入您的电话")
        .li.clearfix
          lable 邮箱
            span *
          input(
            type="text",
            v-model="email",
            placeholder-class="namePlace",
            placeholder="请输入您的邮箱"
          )
        .li.clearfix
          lable 留言
            span *
          textarea(v-model="todo", placeholder="我们能为您做什么")
        button 提交
      .hcontact
          h3.conh3 CONTACT INFORMATION
          h4.conh4 联系方式
          img(src="../assets/img/index/h5img11.png")
          .group
            p 联系电话：021-67186708
            p 工作时间：周一至周五09:00-17:30
            p(style="border:0 !important;") 联系地址：上海市闵行区申昆路1999号
            p(style="border:0 !important;height:0.5rem;line-height: 0.2rem;")
              span(style="color:#fff") 联系地址：
              span 星月德必5号楼503室
          

    




  .header(v-else)
    //- img(src="../assets/img/index/img (30).png")
    div.banner
      video(src="../assets/video/video.mp4" ref="video" autoplay muted controls)
        p 你的浏览器不支持video标签
    .profile.wow.bounceInRight(data-wow-delay="0.2s")
      .content
        .left
          h3 COMPANY PROFILE
          h4 集团简介
          p 上海亿联体创业孵化器管理有限公司（简称亿联体集团）创立于2018年，是一家致力于通过健康产业投资布局，高新科技企业孵化帮扶，文教创新，整合全国医疗健康垂直领域优质资源等方式，打造多元化全产业链的投资商业集团。

          router-link(to="/companyIntro")
            .btn 查看更多
        .right
          img(src="../assets/img/index/img (19).png")
    .business
      .content
        .top
          .left
            h3 CORE BUSINESS
            h4 核心业务
          .right 
            p 国内先进的产业园区运营商，致力于打造“大健康”全产业链整合平台
        .fot.wow.bounceInUp(data-wow-delay="0.2s")
          .li
            img(src="../assets/img/index/img (21).png")
            .min
              h6 对外投资
              p 集团投资重点布局互联网医疗、健康管理咨询、医疗产品研发生产、综合医美服务、功能营养食品等投资领域，打造大健康全产业链投资平台和专业化产业服务平台。
          .li
            img(src="../assets/img/index/img (22).png")
            .min
              h6 资源整合
              p 集团通过控股孵化器平台，投资医疗健康企业，组建医生集团、布局国际贸易、开展互联网大数据医疗产业等多个业务线，将行业资源通过产业链条打通，实现横纵联合。
          .li
            img(src="../assets/img/index/img (23).png")
            .min
              h6 产业孵化
              p 集团拥有孵化器产业园区16个，立足大健康相关领域全产业链资源对园区内在孵企业进行创业帮扶，吸引了大量的医疗健康企业，商业人才，互联网信息服务商入驻。
    .hatch
      .content
        .top
          .left
            h3 INCUBATION ENTERPRISE
            h4 孵化企业
          .right 
            p 汇聚美好力量，赋能企业发展 
        .fot.wow.bounceInUp(data-wow-delay="0.2s")
          //- router-link(to="/zhongkeYC")
          //-   #fh1.li
          //-     img(src="../assets/img/index/img (24).png")
          //-     img(
          //-       src="../assets/img/index/img (4).png",
          //-       style="width: 111px; height: 44px"
          //-     )
          //-     .hatchCompanyHover
          //-       .whiteBorder
          //-       h4 中科医创
          //-       p 公司以CRO、 PBM 、数字健康社群营销、三大主营业务构筑商业蓝图。
          //-     span 中科医创 >>
          //-     span.more 查看更多 >>
          router-link(to="/zhongxinGH")
            #fh2.li
              img(src="../assets/img/index/img (3).png")
              img(
                src="../assets/img/index/img (18).png",
                style="width: 59px; height: 57px"
              )
              .hatchCompanyHover
                .whiteBorder
                h4 中鑫广汇
                p 致力于为研发、生产、流通、临床、等机构搭建信息交换和项目对接的医疗物流大数据平台。
              span 中鑫广汇 >>
              span.more 查看更多 >>
          router-link(to="/yilianGl")
            #fh3.li 
              img(src="../assets/img/index/img (25).png")
              img(
                src="../assets/img/index/img (31).png",
                style="width: 166px; height: 43px"
              )
              .hatchCompanyHover
                .whiteBorder
                h4 亿联管理
                p 以70个以上的服务场景覆盖等优势，为药企和代理商提供合规管理服务，以确保合作伙伴业务合规。
              span 亿联管理 >>
              span.more 查看更多 >>
          router-link(to="/chanyeYQ")
            #fh4.li 
              img(src="../assets/img/index/img (26).png")
              img(src="../assets/img/index/img (2).png",style="width: 42px; height: 55px")
              .hatchCompanyHover
                .whiteBorder
                h4 产业园区
                p 立足大健康相关领域全产业链资源对园区内在孵企业进行创业帮扶，吸引了大量企业入驻园区。
              span 产业园区 >>
              span.more 查看更多 >>
          router-link(to="/chanyeYQ")
            #fh4.li 
              //- img(src="../assets/img/index/img (26).png")
              //- img(src="../assets/img/index/img (2).png",style="width: 42px; height: 55px")
              //- .hatchCompanyHover
              //-   .whiteBorder
              //-   h4 产业园区
              //-   p 立足大健康相关领域全产业链资源对园区内在孵企业进行创业帮扶，吸引了大量企业入驻园区。
              //- span 产业园区 >>
              //- span.more 查看更多 >>
    .info
      .content
        .top
          .left
            h3 CORPORATE NEWS
            h4 企业资讯
          .right
            router-link(to="/companyInfo") 
              p MORE
        .fot.wow.bounceInRight(data-wow-delay="0.2s")
          router-link(to="/companyInfo")
            .li
              img(src="../assets/img/index/img1.png")
              .min 
                .top
                  h6 科技部将开展国家级科技企业孵...
                  span 2021.11.08
                p 11月8日消息，科技部火炬中心通知，将开展2021年度国家级科技企业孵化器认定工作。
          router-link(to="/companyInfo")
            .li
              img(src="../assets/img/index/img3.png")
              .min 
                .top
                  h6 2021中国大健康产业峰会在深...
                  span 2021.11.05
                p 11月5日，2021年（第五届）中国大健康产业峰会在深圳落幕。大会围绕“‘健康中国’新10年 开启创新2.0时代”
          router-link(to="/companyInfo")
            .li
              img(src="../assets/img/index/img2.png")
              .min 
                .top
                  h6 亿联体：数字化让“大健康”产...
                  span 2020.12.11
                p 今年突如其来的新冠肺炎疫情，使得许多企业迎来“寒冬”，经历了最为艰难的一年，但同时也有不少企业逆风翻盘，实现了新的飞跃。今年3月成功落地园区的浙江亿联体科技有限公司项目就是其中一员。
          router-link(to="/companyInfo")
            .li
              img(src="../assets/img/index/img (27).png")
              .min 
                .top
                  h6 中欧医疗健康国际合作分会近日...
                  span 2019.07.04
                p 近日，由我国著名心脏病学家高润霖院士任名誉会长，青年心脏病学家卢长林教授任会长的中国欧洲经济技术合作协会医疗健康国际合作分会（简称“中欧医疗健康国际合作分会”）日前成立。
          router-link(to="/companyInfo")
            .li
              img(src="../assets/img/index/img (28).png")
              .min 
                .top
                  h6 以医为本，跨界融合，创新发展
                  span 2018.12.25
                p 2018年12月23日上午，首届医+论坛在上海奉贤开幕。本届论坛由商务部中国欧洲经济技术合作协会医疗健康国际合作分会主办，北京永大税务师事务所有限公司协办，上海亿联体创业孵化器管理有限公司承办。
          router-link(to="/companyInfo")
            .li
              img(src="../assets/img/index/img (29).png")
              .min 
                .top
                  h6 加速新兴产业升级！重庆江津综...
                  span 2018.10.24
                p 10月23日上午，重庆江津区传来好消息，在上午举行的江津区第三季度招商引资项目集中签约仪式上，共有27个项目总投资达323亿元落户江津，引资总额较去年第三季度同比增长逾30％。加上前两季度的招商签约项目，前三...
    .partner
      .content
        h4 COOPERATIVE PARTNER
        h5 合作伙伴
        .min.wow.bounceInUp(data-wow-delay="0.2s")
          .li
            img(src="../assets/img/index/img (7).png")
          .li
            img(src="../assets/img/index/img (8).png")
          .li
            img(src="../assets/img/index/img (9).png")
          .li
            img(src="../assets/img/index/img (10).png")
          .li
            img(src="../assets/img/index/img.png")
          .li
            img(src="../assets/img/index/img (11).png")
          .li
            img(src="../assets/img/index/img (12).png")
          .li
            img(src="../assets/img/index/img (1).png")
          .li
            img(src="../assets/img/index/img (13).png")
          .li
            img(src="../assets/img/index/img (14).png")
          .li
            img(src="../assets/img/index/img (43).png")
          .li
            img(src="../assets/img/index/img (44).png")
          .li
            img(src="../assets/img/index/img (45).png")
          .li
            img(src="../assets/img/index/img (46).png")
          .li
            img(src="../assets/img/index/img (47).png")
          .li
            img(src="../assets/img/index/img (48).png")
          .li
            img(src="../assets/img/index/img (49).png")
          .li
            img(src="../assets/img/index/img (50).png")
          .li
            img(src="../assets/img/index/img (51).png")
          .li
            img(src="../assets/img/index/img (52).png")
    .cooperation
      .min
        h2 COOPERATIVE APPLICATION
        h3 合作申请
        .from.wow.bounceInUp(data-wow-delay="0.2s")
          .input
            span.lable 姓名：
            input(type="text", v-model="name", placeholder="请输入您的姓名")
            b *
          .input
            span.lable 电话：
            input(type="text", v-model="phone", placeholder="请输入您的电话")
            b *
          .input
            span.lable 邮箱：
            input(
              type="text",
              v-model="email",
              placeholder-class="namePlace",
              placeholder="请输入您的邮箱"
            )
            b *
          .input
            span.lable 留言：
            textarea(v-model="todo", placeholder="我们能为您做什么")
            b *
        button 提交

    .contact
      .mins.wow.bounceInRight(data-wow-delay="0.2s")
        .left
          h2 CONTACT INFORMATION
          h3 联系方式
          p 联系电话：021-67186708
          //- p 邮 箱：XXX@.COM
          p 工作时间：周一至周五09:00-17:30
          p 联系地址：上海市闵行区申昆路1999号星月德必5号楼503室
        img(src="../assets/img/index/img (17).png")
</template>

<script>
import { WOW } from "wowjs";
export default {
  name: "Index",
  data() {
    return {
      play:false,
      name: "",
      phone: "",
      email: "",
      todo: "",
      swiperOption:{
          //显示分页
          pagination: {
            el: '.swiper-pagination'
          },
      }
    };
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  created(){
    if($store.state.pcshow){
      this.$nextTick(() => {
        this.$refs.video.play()
      });
    } 
  }
};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) {
      .con{
        width 7.5rem
        background: #f5f5f5

        .video{
          font-size 0
          video{
            width 7.5rem
          }
        }
        .hprofile{
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          background #F5F5F5
          padding-bottom 0.32rem

          img{
            display: block
            width: 5rem
            height: 3rem
            margin-bottom: 0.24rem
          }
          p{
            font-size: 0.24rem
            font-family: Source Han Sans CN
            font-weight: 400
            color: #333333
            line-height: 0.45rem
            margin-right:0.36rem
            margin-bottom:0.32rem
          }
          .btn{
            display: block
            width: 2rem
            height: 0.68rem
            background: #0085D0
            border-radius: 0.34rem
            line-height: 0.68rem
            font-size: 0.26rem
            font-family: Source Han Sans CN
            font-weight: 400
            color: #FFFFFF
            text-align: center
          }
        }
        .hbusiness{
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          background #FFFFFF
          padding-bottom:0.31rem

          img{
            display block
            width 6.83rem
            height 2.62rem
            margin-bottom 0.22rem
          }
          img:last-child{
            margin-bottom:0
          }

          .btn{
            display: block
            width: 2rem
            height: 0.68rem
            background: #0085D0
            border-radius: 0.34rem
            line-height: 0.68rem
            font-size: 0.26rem
            font-family: Source Han Sans CN
            font-weight: 400
            color: #FFFFFF
            text-align: center
          }
        }
        .hhatch{
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          background #FFFFFF
          margin-top: 0.4rem

          .ul{
            padding-bottom 0.21rem

            a{
              display: block
              float: left

              .li{
                width 3.22rem
                box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
                border-radius: 0px 0px 0.1rem 0.1rem;
                height:4.93rem
                margin-bottom:0.33rem;
                position: relative

                img{
                  display block
                  width 3.22rem
                  height 2.02rem
                }

                h4{
                  font-size: 0.3rem;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #333333;
                  padding:0.19rem 0 0.1rem 0.23rem
                }

                p{
                  font-size: 0.24rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                  line-height: 0.34rem;
                  padding:0rem 0.24rem 0rem 0.24rem
                }
                .btn{
                  width: 1.28rem;
                  height: 0.48rem;
                  background: #0085D0;
                  border-radius: 0.24rem;
                  font-size: 0.22rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height:0.48rem;
                  text-align: center;
                  position:absolute;
                  right:0.18rem;
                  bottom:0.2rem;
                }
              }
            }
            a:nth-child(2n-1){
              margin-right:0.39rem
            }
            
          }
        }
        .hinfo{
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          position: relative
          
          .infoagroup{
            display: block
            width: 6.8rem;
            background: #FFFFFF;
            border-radius: 0.1rem;

            a{
              
              .li{
                border-bottom:0.01rem solid #EAEAEA;
                margin:0 0.22rem
                padding:0.31rem 0
                
                img{
                  display block
                  width: 2.48rem
                  height: 1.28rem
                  float: left
                  margin-right: 0.25rem
                }

                .new{
                  display: block
                  float: left

                  .newtitle{
                    width: 3.6rem;
                    height: 0.58rem;
                    font-size: 0.24rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 0.35rem;
                  }

                  .newtime{
                    font-size: 0.24rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    line-height: 0.39rem;
                    margin-top:0.3rem
                  }

                }
              }
            }
            :last-child{
              .li{
                border-bottom:0
              }
            }
          }
        }
        .hpartner{
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          position: relative
          overflow: hidden;
         
         .scro{
            overflow: scroll;

            .imggroup{
              width:26.48rem;

              .li{
                display flex
                width: 2.16rem;
                height: 1.3rem;
                float: left 
                margin-right 0.45rem
                align-items: center;
                justify-content: center;
                // border: 1px solid #ccc;
                background: #fff;
                margin-bottom:0.16rem


                img{
                  display block
                  width: 2.16rem;
                  height: 1.3rem;
                  margin:0 auto;

                }

              }

              .li:nth-child(10),.li:nth-child(20){
                margin-right 0.35rem
              }
            }
          }
          .scro::-webkit-scrollbar {
            /*隐藏滚轮*/
            display: none;
          }
        }
        .hcooperation{
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          position: relative
          margin-top:-0.16rem
        }
        .from{
          background: #ffffff;
          padding-left:0.34rem;
          width:calc( 100% - 0.34rem );
          
          .li{
            border-bottom:0.01rem solid #EAEAEA;

            lable{
              width: 0.8rem;
              font-size: 0.3rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 0.5rem;
              float: left;
              height:1rem;
              line-height:1rem;

              
              span{
                color:#EE0A24;
              }
            }
            input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
              color: #999999;
              font-weight: 500;
              font-family: PingFang SC;
            }


            input{
              width:5.15rem;
              height:1rem;
              line-height:1rem;
              border:none;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              font-size: 0.3rem;
              float:left
              margin-left:0.39rem;
            }
            textarea{
              margin-left:0.1rem
              width: 5.67rem;
              height: 1.94rem;
              background: #F5F5F5;
              float:left;
              font-size: 0.3rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 0.5rem;
              margin-top: 0.24rem;
              padding-left:0.3rem;
              padding-top:0.24rem;
              resize:none;
            }
          }
          .li:nth-child(4){
            border-bottom:0;
          }
          button{
            width: 6.8rem;
            height: 0.88rem;
            line-height: 0.88rem;
            background: #0085D0;
            border-radius: 0.44rem;
            font-size: 0.3rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            border: 0;
            margin: 0.65rem 0 0.4rem 0
          }
        }
        .hcontact{
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          padding-bottom:0.4rem;

          img{
            display: block;
            width:6.82rem;
            height: 3.22rem;
          }

          .group{
            width:6.21rem;
            padding-left: 0.24rem;
            padding-right:0.35rem;
            background:#ffffff;
            border-bottom-left-radius:0.1rem;
            border-bottom-right-radius:0.1rem;

            p{
              display block
              width: 6.21rem;
              height:1.02rem;
              line-height:1.02rem;
              font-size: 0.3rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              border-bottom:0.01rem solid #EAEAEA;
            }
            p:last-child{
              border:0;
            }
          }
        }
      }
      .conh3{
        padding-top: 0.4rem
        font-size: 0.24rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .conh4{
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        padding 0.15rem 0 0.24rem 0
      }
      .conh5{
        position: absolute
        top 0.84rem
        right 0.34rem
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      
  }
  @media screen and (min-width: 960px) { 
    .header {
      padding-top: 90px;
      
      .banner{
        width 100%;
        video {
          width 100%
        object-fit: fill;
        }
      }
      >img {
        width: 100%;
      }

      .profile {
        padding: 120px 0 124px;

        .content {
          width: 1280px;
          margin: 0 auto;
          display: flex;

          .left {
            width: 751px;

            h3 {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
            }

            h4 {
              margin-top: 23px;
              font-size: 26px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
            }

            p {
              font-size: 16px;
              color: #333333;
              font-family: Source Han Sans CN;
              // text-indent: 15px;
              margin-top: 60px;
              margin-bottom: 60px;
              line-height: 30px;
            }

            .btn {
              width: 106px;
              height: 40px;
              font-size: 15px;
              text-align: center;
              cursor: pointer;
              line-height: 40px;
              border: 1px solid #0085D0;
              color: #0085D0;

              &:hover {
                background: #0085D0;
                color: #FFFFFF;
              }
            }
          }

          .right {
            width: 498px;

            img {
              width: 100%;
            }
          }
        }
      }

      .business {
        background: url('../assets/img/index/img (20).png');
        background-size: cover;

        .content {
          width: 1280px;
          margin: 0 auto;
          padding-top: 115px;
          padding-bottom: 140px;

          .top {
            display: flex;
            justify-content: space-between;

            .left {
              h3 {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
              }

              h4 {
                margin-top: 23px;
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
              }
            }

            .right {
              p {
                color: #FFFFFF;
                margin-top: 53px;
                font-size: 16px;
              }
            }
          }

          .fot {
            display: flex;
            justify-content: space-between;
            margin-top: 65px;

            .li {
              width: 412px;

              img {
                width: 100%;
                display: block;
              }

              .min {
                background: #FFFFFF;
                padding-top: 10px;

                h6 {
                  width: 341px;
                  margin-left: 21px;
                  height: 59px;
                  line-height: 59px;
                  border-bottom: 1px solid #0085D0;
                }

                p {
                  width: 375px;
                  margin-top: 20px;
                  margin-left: 20px;
                  font-size: 14px;
                  color: #333;
                  padding-bottom: 35px;
                  height: 75px;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }

      .hatch {
        .content {
          width: 1280px;
          margin: 0 auto;
          padding-top: 120px;
          padding-bottom: 140px;

          .top {
            display: flex;
            justify-content: space-between;

            .left {
              h3 {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
              }

              h4 {
                margin-top: 23px;
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
              }
            }

            .right {
              p {
                color: #666666;
                margin-top: 53px;
                font-size: 16px;
              }
            }
          }

          .fot {
            display: flex;
            justify-content: space-between;
            margin-top: 63px;

            .li {
              width: 272px;
              position: relative;
              overflow: hidden;

              &:hover {
                // 划入效果
                .hatchCompanyHover {
                  transition-duration: 0.8s;
                  transform: translateY(-310px);
                  opacity: 1;
                }

                .more {
                  opacity: 1;
                }

                span:nth-child(4) {
                  transition-duration: 0.6s;
                  opacity: 0;
                }
              }

              img:first-child {
                width: 100%;
              }

              img:nth-child(2) {
                position: absolute;
                top: 16px;
                left: 16px;
              }

              .hatchCompanyHover {
                opacity: 0;
                position: absolute;
                margin-right: 15px;
                top: 430px;
                left: 16px;
                color: #FFFEFE;
                cursor: pointer;
                font-family: Source Han Sans CN;

                .whiteBorder {
                  width: 12px;
                  margin-bottom: 18px;
                  border-bottom: 4px solid #fff;
                }

                h4 {
                  height: 19px;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 30px;
                }

                p {
                  margin-top: 18px;
                  width: 240px;
                  height: 76px;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 30px;
                }
              }

              span {
                position: absolute;
                bottom: 26px;
                left: 16px;
                color: #FFFEFE;
                cursor: pointer;
              }

              .more {
                opacity: 0;
              }
            }
          }
        }
      }

      .info {
        background: #F8F9FA;

        .content {
          width: 1280px;
          margin: 0 auto;
          padding-top: 120px;
          padding-bottom: 140px;

          .top {
            display: flex;
            justify-content: space-between;

            .left {
              h3 {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
              }

              h4 {
                margin-top: 23px;
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
              }
            }

            .right {
              p {
                color: #666666;
                margin-top: 53px;
                font-weight: bold;
                font-size: 16px;

                &:hover {
                  color: #0085D0;
                }
              }
            }
          }

          .fot {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .li:nth-child(2) {
              border-top: 0;
            }

            .li:nth-child(1) {
              border-top: 0;
            }

            .li {
              border-top: 1px solid #E3E3E3;
              margin-top: 18px;
              padding-top: 17px;
              width: 600px;
              cursor: pointer;
              display: flex;
              justify-content: space-between;

              img {
                width: 195px;
                height: 118px;
              }

              .min {
                &:hover {
                  .top {
                    color: #0085D0;

                    h6 {
                      color: #0085D0;
                    }
                  }
                }

                .top {
                  width: 388px;
                  display: flex;
                  justify-content: space-between;

                  h6 {
                    font-size: 20px;
                    width: 300px;
                    color: #333333;
                    margin-bottom: 16px;

                    &:hover {
                      color: #0085D0;
                    }
                  }

                  span {
                    width: 79px;
                    font-size: 16px;
                  }
                }

                p {
                  width: 388px;
                  line-height: 20px;
                  font-size: 14px;
                  color: #333333;
                }
              }
            }
          }
        }
      }

      .partner {
        .content {
          width: 1280px;
          margin: 0 auto;
          padding-top: 120px;
          padding-bottom: 119px;

          h4 {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            text-align: center;
          }

          h5 {
            margin-top: 23px;
            font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            text-align: center;
          }

          .min {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .li {
              width: 216px;
              height: 130px;
              border: 1px solid #ccc;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 60px;

              img {
                width: 181px;
                height: 86px;
              }
            }
          }
        }
      }

      .cooperation {
        background: url('../assets/img/index/img (15).png') no-repeat;
        background-size: cover;

        .min {
          width: 1200px;
          margin: 0 auto;
          padding-top: 111px;
          padding-bottom: 136px;

          h2 {
            font-size: 30px;
            font-weight: bold;
            color: #333333;
            height: 24px;
            line-height: 24px;
            text-align: center;
          }

          h3 {
            font-size: 30px;
            color: #333333;
            line-height: 39px;
            font-weight: 400;
            text-align: center;
            margin-top: 18px;
            margin-bottom: 80px;
          }

          .from {
            display: flex;
            flex-wrap: wrap;

            .input {
              display: flex;
              align-items: center;
              margin-bottom: 21px;

              .lable {
                font-size: 15px;
                font-weight: 400;
                color: #333333;
                width: 50px;
                margin-left: 30px;
              }

              input {
                width: 287px;
                height: 36px;
                border: 1px solid #EAEAEA;
                padding-left: 7px;
                line-height: 36px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;

                &:focus {
                  outline: none;
                  color: #333333;
                  border: 1px solid #EAEAEA;
                }
              }

              b {
                color: #F82130;
                margin-left: 8px;
              }

              textarea {
                width: 1049px;
                height: 99px;
                border: 1px solid #EAEAEA;
                padding: 11px 7px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;

                &:focus {
                  color: #333333;
                  outline: none;
                  border: 1px solid #EAEAEA;
                }
              }
            }
          }

          button {
            width: 244px;
            cursor: pointer;
            height: 44px;
            background: #0085D0;
            text-align: center;
            line-height: 44px;
            margin-top: 24px;
            border: 0;
            margin-left: 78px;
            color: #fff;
          }
        }
      }

      .contact {
        margin-bottom: 119px;
        margin-top: 100px;

        .mins {
          width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;

          .left {
            h2 {
              font-size: 30px;
              color: #333333;
              line-height: 39px;
              margin-top: 90px;
              margin-bottom: 18px;
            }

            h3 {
              height: 29px;
              line-height: 29px;
              font-size: 30px;
              margin-bottom: 50px;
            }

            p {
              font-size: 20px;
              height: 20px;
              line-height: 20px;
              margin-top: 31px;
            }
          }

          img {
            width: 629px;
            height: 629px;
          }
        }
      }
    }
  }
</style>